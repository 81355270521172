<script>
import GlobalHotkeys from 'components/GlobalHotkeys.vue'
import UserMenu from 'components/UserMenu.vue'
import UserAvatar from 'components/UserAvatar.vue'
import { mainLinks, socialLinks, mobileLinks } from 'src/router/navigation.js'
import NavLink from 'components/NavLink.vue'
import LogoImage from 'components/LogoImage.vue'
import { useUpdateState } from 'src/lib/update.js'
import TextButton from 'components/TextButton.vue'
import { useApp } from 'src/lib/app.js'
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import { useGlobalStorage } from 'src/lib/storage.js'
import { useAuth } from 'src/lib/auth.js'
import { t } from 'src/lib/i18n.js'

export default {
  components: { GlobalHotkeys, UserMenu, UserAvatar, NavLink, LogoImage, TextButton },
  setup () {
    const updateState = useUpdateState()
    const quasar = useQuasar()
    const app = useApp()
    const router = useRouter()
    const route = useRoute()
    const globalStorage = useGlobalStorage()
    const leftDrawerOpened = ref(false)
    const headerState = ref(true)
    const client = ref(false)
    const mainContent = ref(null)
    const auth = useAuth()
    const serverEnv = ref(null)

    const toggleLeftDrawer = () => {
      leftDrawerOpened.value = !leftDrawerOpened.value
    }
    const reload = () => {
      window.location.reload()
    }
    const header = ref(null)

    onMounted(async () => {
      window.__HEADER__ = header.value
      client.value = true

      try {
        serverEnv.value = await fetch('/env').then(x => x.json())
      } catch (err) {
        console.warn('Could not fetch server env', err)
      }
    })

    const section = computed(() => route.value?.matched?.map(x => x.meta)?.reduce((a, b) => ({ ...a, ...b }), {})?.section ?? null)
    const leftDrawerOpen = computed(() => quasar.screen.lt.md && leftDrawerOpened.value)
    const socialSets = computed(() => [
      socialLinks.slice(0, 2),
      socialLinks.slice(2, 4)
    ])
    const allLinks = computed(() => [
      ...mainLinks,
      ...socialLinks,
      ...Array.from(route)
    ])
    const clientVersion = ref(process.env.BUILD_NUMBER)
    const version = computed(() => isNaN(+clientVersion.value[0]) ? clientVersion.value : `v${clientVersion.value}`)
    const loginLink = computed(() => {
      const redirect = route?.fullPath
      if (redirect && redirect !== '/') {
        return `/login?redirect=${encodeURIComponent(redirect)}`
      } else {
        return '/login'
      }
    })

    const globalHideHeader = computed(() => globalStorage.hideHeader)

    watch(globalHideHeader, (hide) => {
      headerState.value = !hide
    })

    const hideFooter = computed(() => !!globalStorage.hideFooter)
    const footerYear = computed(() => new Date().getUTCFullYear())

    const printVersion = () => {
      console.log('Version:', {
        clientVersion: clientVersion.value,
        ...serverEnv.value
      })
    }

    const holiday = computed(() => {
      if (!client.value) return false

      const now = new Date()

      return now.getMonth() === 9
        ? 'halloween'
        : null
    })

    return {
      t,
      hideFooter,
      quasar,
      allLinks,
      app,
      auth,
      client,
      clientVersion,
      footerYear,
      globalHideHeader,
      globalStorage,
      header,
      headerState,
      leftDrawerOpen,
      leftDrawerOpened,
      loginLink,
      mainContent,
      mainLinks,
      mobileLinks,
      reload,
      router,
      section,
      socialLinks,
      socialSets,
      toggleLeftDrawer,
      updateState,
      version,
      printVersion,
      holiday
    }
  }
}
</script>
<template>
  <q-layout view="hHh Lpr ffr" class="MainLayout shade-0">
    <TextButton @click="mainContent.focus()" class="z-max skip bg-shade-0" tabindex="0">
      Skip to Main Content
    </TextButton>
    <div id="top" />
    <GlobalHotkeys />
    <q-header elevated class="bg-white text-dark"
              :reveal="quasar.screen.lt.md"
              :reveal-offset="1"
              :model-value="!globalStorage.hideHeader || globalStorage.forceHeader"
              ref="header"
    >
      <q-toolbar class="large-toolbar"

                 :class="{
                   'bg-grey-10': quasar.dark.isActive,
                   'text-white': quasar.dark.isActive
                 }"
      >
        <router-link to="/" class="desktop-logo-container">
          <img src="~assets/logo.svg" class="logo" v-if="!quasar.dark.isActive && !holiday">
          <img src="~assets/logo-dark.svg" class="logo" v-if="quasar.dark.isActive && !holiday">
          <img src="~assets/logo-halloween.svg" class="logo" v-if="!quasar.dark.isActive && holiday === 'halloween'">
          <img src="~assets/logo-dark-halloween.svg" class="logo" v-if="quasar.dark.isActive && holiday === 'halloween'">
        </router-link>
        <!-- Main links across the top -->

        <div class="gt-sm header-links">
          <span
            v-for="(link, index) in mainLinks"
            :key="index"
          >
            <q-btn
              class="header-link"
              :class="{
                'text-primary': link.section === section,
                hidden: link.if && (!client || link.if({auth, app}) === false)
              }"
              flat
              no-caps
              type="a"
              :to="link.to"
              :href="link.href"
            >
              {{ link.titleKey ? t(link.titleKey) : link.title }}
              <q-badge v-if="client && link.badge" color="primary" floating>
                {{ link.badge }}
              </q-badge>
            </q-btn>
          </span>
        </div>
        <q-space />
        <!-- User menu, login, logout, etc -->
        <UserMenu class="gt-sm" />

        <!-- Hamburger menu on smaller devices. -->
        <q-no-ssr>
          <UserAvatar
            v-if="auth.loggedIn"
            :user="auth.profile" size="42px"
            @click="toggleLeftDrawer"
            class="lt-md"
          />
        </q-no-ssr>
        <q-no-ssr>
          <q-btn
            flat
            dense
            round
            icon="fas fa-bars"
            aria-label="Menu"
            class="lt-md"
            @click="toggleLeftDrawer"
          />
        </q-no-ssr>
      </q-toolbar>
    </q-header>
    <div data-info="hamburger-icon" class="bg-primary" :height-hint="80" style="position: fixed; top: 0; right: 0; max-width: 59px; z-index: 10" v-if="globalStorage.hideHeader && !globalStorage.forceHeader">
      <q-toolbar>
        <q-btn flat round
               color="white"
               dense icon="fas fa-bars"
               @click="globalStorage.forceHeader = true"
        />
      </q-toolbar>
    </div>
    <q-drawer
      :model-value="leftDrawerOpen"
      @update:modelValue="toggleLeftDrawer"
      bordered
      :content-class="quasar.dark.isActive ? 'bg-dark' : 'bg-white'"
    >
      <div id="left-content" />
      <q-list>
        <NavLink v-for="(link, index) in mobileLinks" :key="index" :link="link" />
        <q-no-ssr>
          <q-item v-if="auth.loggedIn && auth.profile">
            <q-item-section top avatar @click="router.push('/settings/professional/personal')">
              <UserAvatar :user="auth.profile" size="48px" />
            </q-item-section>
            <q-item-section @click="router.push('/settings/professional/personal')">
              <q-item-label>{{ auth.profile?.renderName }}</q-item-label>
              <q-item-label caption>
                {{ auth.profile?.email }}
              </q-item-label>
            </q-item-section>
            <q-item-section side top>
              <q-btn color="warning" size="xs" @click="auth.logout()">
                Log out
              </q-btn>
            </q-item-section>
          </q-item>

          <q-item v-if="!auth.profile">
            <q-item-section>
              <q-btn color="primary" :to="loginLink">
                {{ t('message.signIn') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-no-ssr>
      </q-list>
    </q-drawer>

    <q-page-container class="page-container">
      <div style="height: 80px;" v-if="globalStorage.hideHeader && !globalStorage.forceHeader">
        <router-link to="/">
          <LogoImage style="width: 138px;" class="Desktop gt-sm q-mt-sm q-ml-md" />
          <LogoImage style="width: 138px;" class="Mobile lt-md q-ma-sm" />
        </router-link>
      </div>
      <a name="main-content" ref="mainContent" tabindex="-1" />
      <router-view />
    </q-page-container>

    <q-footer class="footer bg-footer" v-if="!hideFooter">
        <div class="content row justify-between">
          <div class="col q-py-lg">
            <div class="row justify-between">
              <div class="copyright-area col-12 col-md-shrink q-mt-xs-md q-mt-md-none">
                <div class="row justify-between">
                  <div class="col">
                    <router-link to="/">
                      <LogoImage style="height: 55px;" icon class="q-ml-sm" dark />
                    </router-link>
                  </div>
                  <div class="col lt-md self-end q-mb-md q-mr-md text-right">
                    <a
                      v-for="link in socialLinks"
                      :key="link.href"
                      :target="link.target"
                      :href="link.href"
                      class="q-mx-sm q-link"
                    >
                      <q-icon
                        :name="link.icon"
                        class="footer-social-icon"
                        size="sm"
                      />
                    </a>
                  </div>
                </div>
                <div class="copyright text-grey-6 q-pa-sm">
                  Copyright &copy; 2018 &ndash; {{ footerYear }}, Jobot LLC. All rights reserved.
                  Jobot name and logo are registered trademarks of Jobot LLC.
                </div>
              </div>
              <div class="col-12 col-md-shrink q-gt-sm boring-links text-no-wrap self-center q-pr-sm bg-footer">
                <router-link to="/contact" class="q-link text-no-wrap inline-block">
                  Contact Us
                </router-link>
                <router-link to="/privacy-policy" class="q-link text-no-wrap inline-block">
                  Privacy Policy
                </router-link>
                <router-link to="/terms-and-conditions" class="q-link text-no-wrap inline-block">
                  Terms &amp; Conditions
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-2 q-px-sm border-left social-panel no-wrap q-py-md gt-sm" style="line-height: 100px;">
            <a
              v-for="link in socialLinks"
              :key="link.href"
              :target="link.target"
              :href="link.href"
              class="q-mx-sm q-link"
            >
              <q-icon
                :name="link.icon"
                class="footer-social-icon"
                size="sm"
              />
            </a>
          </div>
        </div>
        <div
          class="version text-grey-9"
          :data-version="version"
        >
          <span @click="printVersion">{{ version }}</span>
          <q-no-ssr>
            <q-btn v-if="updateState.newVersionAvailable" icon="fas fa-rotate"
                  flat
                  round
                  size="sm" color="grey-9"
                  @click="reload"
            />
          </q-no-ssr>
        </div>
        <q-separator />
    </q-footer>
  </q-layout>
</template>
<style lang="scss" scoped>
  .logo {
     vertical-align: middle;
  }
  @media (max-width: $breakpoint-sm-max) {
    .page-container {
      margin-left: 3px;
      margin-right: 3px;
    }
    .logo {
      width: 83px;
      height: 28px;
      line-height: 42px;
    }
    .desktop-logo-container {
      width: 130px;
      height: 53px;
      line-height: 53px;
    }
  }

  @media (min-width: $breakpoint-md-min) {
    .logo {
      width: 138px;
      line-height: 80px;
    }
    .large-toolbar {
      height: 80px;
    }
    .desktop-logo-container {
      width: 195px;
      height: 80px;
      line-height: 80px;
    }
    .footer {
      .border-left {
        border-left: solid 1px #484848;
      }
      .social-panel {
        min-width: 180px;
      }
    }
  }
  .desktop-logo-container {
    text-align: center;
    border-right: solid 1px #e0e0e0;
    margin-left: -12px;
  }
  .header-links {
    margin-left: 13px;
  }
  .header-link {
    font-size: 19.2px;
    margin-right: 8px;
  }
  .socials {
    margin-right: 46px;
    margin-bottom: 7px;
  }
  .social {
    margin-right: 2px;
  }

  .bg-footer {
    background-color: #303030;
  }
  .footer {
    hr {
      background-color: #484848;
    }
    .footer-social-icon {
      color: #989898;
    }
    .social-panel {
      min-width: 100px;
    }
   }

  .version {
    position: absolute;
    bottom: 2px;
    right: 2px;
  }

  .boring-links .q-link {
    color: #ccc;
    margin-left: 15px;
  }

  .skip {
    position: fixed;
    top: 0;
    left: 80px;
    transform: translateY(-35px);
    outline: solid 3px $primary;
    padding: 4px;
    &:focus {
      transform: translateY(3px);
    }
    transition: transform .25s ease-out;
  }

  @media (max-width: $breakpoint-sm-max) {
    .copyright {
      font-size: 13px;
    }
    .copyright-area {
      order: 1
    }
  }
</style>
